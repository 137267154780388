import React from 'react'
import { Box, Grommet, Main, ResponsiveContext } from 'grommet'
import Div100vh from 'react-div-100vh'
import Navigation from './navigation'
import { Footer } from './Footer'
import { customTheme } from '../theme'
import GlobalFonts from '../fonts/fonts'
import './layout.css'
import 'typeface-lato'
import BackgroundPic from '../assets/backgroundhomepagepicv3.jpg'

const Template = ({ children, height, isLanding }) => (
  <Grommet
    background={
      isLanding ? { image: `url(${BackgroundPic})`, size: 'cover' } : '#F6F3EA'
    }
    theme={customTheme}
    style={{ height: 'auto', width: '100%' }}
    full
  >
    <GlobalFonts />
    <ResponsiveContext.Consumer>
      {size => (
        <Div100vh
          style={(size === 'small' || height) && { minHeight: '100rvh' }}
        >
          <Box fill height={{ min: '100%' }}>
            <Box margin="auto" fill>
              <Navigation isLanding={isLanding} />
              <Main flex fill={false} pad={{ horizontal: 'medium' }}>
                {children}
              </Main>
              <Footer isLanding={isLanding} />
            </Box>
          </Box>
        </Div100vh>
      )}
    </ResponsiveContext.Consumer>
  </Grommet>
)

export default Template
